export default {
    zh:{
        ttPay:{
            queryParam:{
                type:'用途分类',
                type_placeholder:'请选择用途分类',
                flag:'是否提交',
                flag_placeholder:'请选择是否提交',
            },
            columns:{
                F_TYPE:'用途分类',
                F_FLAG:'是否提交',
                F_AMOUNT:'订单金额',
            },
            form:{
                customerAmountId:'客户',
                customerAmountId_placeholder:'请选择客户',
                customerAmountId_rule: '请选择客户',
                type:'用途分类',
                type_placeholder:'请选择用途分类',
                type_rule: '请选择用途分类',
            },
            TtPayDetail:{
                F_ORDER_ID:'订单ID',
                F_ORDER_ID_PLACEHOLDER:'请输入订单ID',
            },
        }
    },
    en:{
        ttPay:{
            queryParam:{
                type:'用途分类',
                type_placeholder:'请选择用途分类',
                flag:'是否提交',
                flag_placeholder:'请选择是否提交',
            },
            columns:{
                F_TYPE:'用途分类',
                F_FLAG:'是否提交',
                F_AMOUNT:'订单金额',
            },
            form:{
                customerAmountId:'客户',
                customerAmountId_placeholder:'请选择客户',
                customerAmountId_rule: '请选择客户',
                type:'用途分类',
                type_placeholder:'请选择用途分类',
                type_rule: '请选择用途分类',
            },
            TtPayDetail:{
                F_ORDER_ID:'订单ID',
                F_ORDER_ID_PLACEHOLDER:'请输入订单ID',
            },
        }
    }
}